
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;500;700&display=swap');

html {
    font-family: 'Manrope', sans-serif !important;
    font-weight: 300;
}

h1, h2, h3, h4, p, label, select, input, li, table, button {
  font-family: 'Manrope', sans-serif !important;
}

h1, h2, h3 {
  font-weight: 700 !important;
}

.page {
  padding-bottom: 150px;
}

.pd-t {
  padding-top: 20px;
}

.alert {
  font-family: 'Manrope', sans-serif !important;
  font-weight: 300;
}

#btc-btn {
  background-color: #F89B20;
  border-color: #FFF;
  border-radius: 25px;
  border-width: 1px;
  padding: 9px 20px;
  margin: 9px;
  font-weight: 500;
  font-size: 0.9rem;
  transition: all .2s;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-delay: 0s;
}

#btc-btn:hover, #btc-btn:active, #btc-btn:focus {
  background-color: #FFC700;
  transition: all .2s;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-delay: 0s;
}

/* NAVBAR STYLING */
.navbar {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fff;
}

.navbar-container {
  padding-top: 20px;
  padding-bottom: 10px;
}

.navbar .container {
  padding-bottom: 5px;
}

.nav-link a {
  color: #050302;
  font-weight: bold;
  text-decoration: none;
}

.nav-link .active {
  color: #03A9F4;
}

/* FOOTER STYLING */
.text-sm {
  font-size: 12px;
}

hr:before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  max-width: 100%;
  width: 100%;
  border: 1px solid grey;
}

#footer-link {
  color: #000000;
  text-decoration: none;
}

#footer-link:hover {
  color: #4e4e4e;
  text-decoration: none;
}

/* HOME PAGE STYLING */
.main {
  text-align: center;
  padding-top: 20px;
}

.container-left {
  text-align: left;
}

.jumbotron {
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-bottom: 40px;
  color: #fff;
  font-weight: 400;
}

.jumbotron h4 {
  font-weight: 300 !important;
}

.jb-col {
  padding-top: 10px;
  padding-bottom: 10px;
}

/* SECURITY FORM STYLING */
#form-card {
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  align-content: center;
}

#form-step {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #bcdceb;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

#form-step:active {
  border-color: #03A9F4;
}

#form-step:focus {
  border-color: #03A9F4;
  outline-style: none;
}

/* PLAID LINK STYLING */
#loading-p {
  color: #212529;
}